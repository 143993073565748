<template src="./template.html"></template>

<script>
export default {
  name: "WaiterTableTabs",
  components: {
    TableList: () => import("@components/lists/TableList/index.vue")
  },
  props: {
    tab: {
      type: Number,
      default: 0
    },
    tables: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentTab: this.tab,
      error: false,
      success: false
    };
  },
  watch: {
    tab(val) {
      this.currentTab = val;
    }
  },
  methods: {
    handleOccupyTable() {
      this.currentTab = 1;
    },
    async updateTable(id, payload) {
      const { waiter = {}, status = "", currentClient = "" } = payload;
      const db = window.firebase.firestore();
      const TablesRef = db.collection("Tables");
      const table = await TablesRef.doc(id)
        .get()
        .then(doc => doc.data());
      const { activesWaiters } = table;
      if (waiter && waiter.id) activesWaiters[waiter.id] = waiter.active;
      const data = {};
      if (status) data.status = status;
      if (currentClient) data.currentClient = currentClient;
      if (activesWaiters) data.activesWaiters = activesWaiters;
      return TablesRef.doc(id).update(data);
    },
    getSelectedTable() {
      const focusFree = this.currentTab === 0;
      const focusBusy = this.currentTab === 1;
      if (focusFree && this.freeTableList) return this.freeTableList.tableSelected;
      if (focusBusy && this.busyTableList) return this.busyTableList.tableSelected;
    },
    async handleChooseTable(table) {
      const { status, id = "" } = table;
      this.$emit("selected", id);
      const isBusy = status === "BUSY";
      const isFree = status === "FREE";
      if (isBusy) {
        const isAnonymus = table.currentClient === "ANONYMUS";
        if (isAnonymus) {
          this.$emit("status", table);
        } else if (table.currentClient && table.currentClient.id) {
          this.$emit("status", table);
        } else {
          this.$emit("search", true);
        }
      }
      /*
      else if (isFree) {
        this.$refs.freeTableList.handleOccupy(table);
      }
      */
    }
  }
};
</script>